import React from 'react'
import styled from 'styled-components';
import StoreItem from './store-item';
import dynamic from 'next/dynamic';
import { useMedia } from 'react-use';
import { Flex } from 'components/layout/shared';
import Loader from 'react-loader-spinner';
import { colors } from 'styles/colors';
import { CarouselContainer } from './reusables';
const OwlCarousel = dynamic(import('react-owl-carousel'),{ssr:false});

export default function StoresCarousel({ data, title, useHeader }) {
	const isMobile = useMedia('(max-width: 550px)')

    return (
        <Section className="arrivals">
            <div className="full-width-container">
                <div className="container">
                    <CarouselContainer className="new">
                        <CarouselTitle>{title}</CarouselTitle>
                        {!data || !data.length ?
                            <Flex align="center" justify="center" style={{ minHeight: 214}}>
                                <Loader type="Oval" color={colors.base} height={35} width={35} />
                            </Flex>
                                :
                            <OwlCarousel className='owl-theme' loop margin={isMobile ? 10 : 20} nav
                            responsive={{
                                0:{
                                    items:3,
                                    nav:true,
                                    loop:data.length > 3 ? true : false,
                                    navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']
                                },
                                600:{
                                    items:4,
                                    nav:true,
                                    loop:data.length > 4 ? true : false,
                                    navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']

                                },
                                1000:{
                                    items:6,
                                    nav:true,
                                    loop:data.length > 6 ? true : false,
                                    navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']
                                }
                            }}
                            >
                                {data.map((item, index) => (
                                    <StoreItem key={index} store={item} useHeader={useHeader} />
                                ))}
                            </OwlCarousel>
                        }
                    </CarouselContainer>
                </div>
            </div>
        </Section>
    )
}

export const Section = styled.section`
    overflow-x: hidden;
    background: white;
    padding: 30px 0;

    @media (max-width: 550px) {
        padding: 20px 0;
    }
`;
const CarouselTitle = styled.h3`
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
`;