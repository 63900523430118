import React, { useState } from "react"
import styled from "styled-components"
import Lottie from "react-lottie"
import animation from "../../animations/section6.json"
import { MainButton } from "../../styles/buttons"
import Loader from "react-loader-spinner"
import { useMedia } from "react-use"
import { Flex } from "components/layout/shared"
import { colors } from "styles/colors"
import { submitBusinessContactForm } from "../../lib/firebase"
import Link from "next/link"
import Spacer from "components/spacer"
export default function ContactSection() {
  const isWide = useMedia("(min-width: 750px)")
  const isVeryWide = useMedia("(min-width: 950px)")

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const onSubmit = ev => {
    ev.preventDefault()
    setLoading(true)
    const elementsArray = [...ev.target.elements]
    const formData = elementsArray.reduce((acc, elem) => {
      if (elem.id) {
        acc[elem.id] = elem.value
      }

      return acc
    }, {})

    submitBusinessContactForm({ ...formData }).then(result => {
      // Read result of the Cloud Function.
      setLoading(false)
      setSuccess(true)
    })
  }
  return (
    <div style={{ position: "relative" }}>
      <Wrapper id="contactus">
        <div>
          <SellingSectionTitle>Sell on Unqueue</SellingSectionTitle>
          <Copy style={{ opacity: 0.8 }}>
            Enjoy integrations, realtime order updates, inventory management,
            tiered pricing, and 15 free orders every month. Oh, and signup takes
            5 minutes.
          </Copy>
          <Link href="#">
            <Copy
              target="_blank"
              href="https://unqueue.app/sell"
              as="a"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              Learn more about Unqueue for Business here
            </Copy>
          </Link>
          <Spacer size={25} />
        </div>
        <ContactFormContainer>
          {success ? (
            <SuccessCopy>
              Thanks! We'll be in contact with you soon.
            </SuccessCopy>
          ) : (
            <ContactForm onSubmit={onSubmit}>
              <Input
                type="text"
                placeholder="Business name"
                required
                name="businessName"
                id="businessName"
              />
              <Input
                type="text"
                placeholder="Your name"
                required
                name="fullName"
                id="fullName"
              />
              <Input
                type="email"
                placeholder="Email address"
                required
                name="email"
                id="email"
              />
              <Input
                type="number"
                placeholder="Phone number"
                required
                name="phoneNumber"
                id="phoneNumber"
              />
              <Textarea
                type="text"
                placeholder="Type your message here..."
                required
                name="message"
                id="message"
                rows={6}
              />
              <Flex align="center" direction="row">
                <MainButton
                  type="submit"
                  style={{
                    marginRight: 15,
                    backgroundColor: colors.base,
                    color: "white",
                  }}
                >
                  Submit
                </MainButton>
                {loading && (
                  <Loader
                    type="Oval"
                    color={colors.businessPrimary}
                    height={40}
                    width={40}
                  />
                )}
              </Flex>
            </ContactForm>
          )}
        </ContactFormContainer>
      </Wrapper>
      <AnimContainer>
        <Lottie
          options={animationOptions}
          height={isVeryWide ? 800 : isWide ? 450 : 240}
          width={"100%"}
          isStopped={false}
          isPaused={false}
          isClickToPauseDisabled={true}
        />
      </AnimContainer>
    </div>
  )
}

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  min-height: 60vh;
  padding: 5vh 20px;

  @media (min-width: 700px) {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 30px;
  }
`
export const SellingSectionTitle = styled.h2`
  font-weight: 400;
  font-size: 40px;
`
const Copy = styled.p`
  font-size: 20px;
  line-height: 1.2;
  max-width: 350px;
`
const SuccessCopy = styled.p`
  font-size: 22px;
  font-weight: 500;
`
const ContactFormContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
  background-color: white;
  border: 1px solid #7070701a;
  border-radius: 5px;
  padding: 30px 20px;
  z-index: 1;
  transition: 0.3s ease;
  box-shadow: 0 2.7px 2.2px rgba(0, 0, 0, 0.016),
    0 6.9px 4.7px rgba(0, 0, 0, 0.026), 0 14.2px 8.7px rgba(0, 0, 0, 0.033),
    0 29.2px 18.6px rgba(0, 0, 0, 0.042), 0 80px 80px rgba(0, 0, 0, 0.06);
`
const ContactForm = styled.form`
  display: grid;
  grid-template-rows: 1fr;
  gap: 10px;
`
const Input = styled.input`
  border: 1px solid #7070704d;
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
  color: ${colors.base};
  font-size: 17px;
`
const Textarea = styled.textarea`
  border: 1px solid #7070704d;
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
  color: ${colors.base};
  font-size: 17px;
`
export const AnimContainer = styled.div`
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`
