import React, { useCallback, useRef } from 'react'
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { colors } from 'styles/colors';
import Link from 'next/link';
import Image from 'next/image';
import { Flex } from 'components/layout/shared';
const OwlCarousel = dynamic(import('react-owl-carousel'),{ssr:false});

export default function LeaderboardSection({ leaderboardCategories }) {

    return (
            <Container className="arrivals container">
                <div className="row banner-row">
                    <TitleSection align="center" className="col-md-4 banner-4">
                        <Title>Welcome to a better way to shop.</Title>
                    </TitleSection>
                    <div className="col-md-8 banner-8">
                        <div className="carousel slide" data-ride="carousel">
                            <OwlCarousel
                                className='owl-theme' 
                                loop
                                margin={10}
                                dots={false}
                                autoplay
                                autoplayTimeout={4000}
                                responsive={{
                                    0:{
                                        items:1,
                                        nav:false,
                                        dots: false,
                                        loop: true,

                                    },
                                    600:{
                                        items:2,
                                        nav:false,
                                        dots: false,
                                        loop: true,
                                    },
                                    1000:{
                                        items:2,
                                        nav:true,
                                        loop:true,
                                        navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']
                                    }
                                }}
                                >
                                    {leaderboardCategories.map((item, index) => (
                                        <Link key={index} href={`/search?categoryId=${item.id}&title=${item.title}`}>
                                            <ImageContainer>
                                                <Image
                                                    src={item.image}
                                                    alt={item.title}
                                                    objectFit="cover"
                                                    layout="fill"
                                                />
                                            </ImageContainer>
                                        </Link>
                                    ))}
                            </OwlCarousel>
                        </div>
                    </div>
                    
                </div>
            </Container>
    )
}
const Container = styled.div`
    padding: 20px 0;
    @media(max-width: 550px) {
        padding: 0;
    }
`;
const Title = styled.h3`
    font-weight: 300;
    font-size: 34px;
    text-align: left;
    color: white;
`;
const ImageContainer = styled.div`
    height: 160px;
    cursor: pointer;
`;

const TitleSection = styled(Flex)`
    @media (max-width: 768px) {
        display: none;
    }
`