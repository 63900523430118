import React from 'react'
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { Flex } from '../layout/shared';
import { colors } from '../../styles/colors';
import { Section } from './stores-carousel';
import SaleItem from './sale-item';
import { useMedia } from 'react-use';
import Loader from 'react-loader-spinner';
import { CarouselContainer } from './reusables';

const OwlCarousel = dynamic(import('react-owl-carousel'),{ssr:false});

export default function SaleCarousel({ data, title }) {

	const isMobile = useMedia('(max-width: 768px)')

    if (isMobile) {
        return (
            <Section className="arrivals">
                <div className="full-width-container">
                    <div className="container">
                        <CarouselContainer className="new">
                            <CarouselTitle>{title}</CarouselTitle>
                            <Flex direction="column">
                                {data.map((item, index) => {
                                    return (
                                        <SaleItem key={index} product={item} />
                                    )
                                })}
                            </Flex>
                        </CarouselContainer>
                    </div>
                </div>
            </Section>
        )
    }

    return (
        <Section className="arrivals">
            <div className="full-width-container">
                <div className="container">
                    <CarouselContainer className="new">
                        <div className="head arrivals-head">
                            <CarouselTitle>{title}</CarouselTitle>
                        </div>
                        {!data || !data.length ?
                            <Flex align="center" justify="center" style={{ minHeight: 420}}>
                                <Loader type="Oval" color={colors.base} height={35} width={35} />
                            </Flex>
                                :
                            <OwlCarousel className='owl-theme' items={3} loop margin={25} nav
                            responsive={{
                                0:{
                                    items:1,
                                    nav:true
                                },
                                600:{
                                    items:2,
                                    nav:true,
                                    loop: true,
                                    autoplay: true,
                                    navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']
                                },
                                1000:{
                                    items:3,
                                    nav:true,
                                    loop:true,
                                    navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']
                                }
                            }}
                            >
                                {data.map((item, index) => {
                                    return (
                                        <SaleItem key={index} product={item} />
                                    )
                                })}

                            </OwlCarousel>
                        }
                    </CarouselContainer>
                </div>
            </div>
        </Section>
    )
}
const CarouselTitle = styled.h3`
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
`;